<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>Login to Dashboard</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user"
                    prepend-icon="mdi-email"
                    name="email"
                    label="Email"
                    type="text"
                  />
                  <v-text-field
                    id="password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="loginMethod"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import SimpleVueValidator from 'simple-vue-validator'
  import { login } from '@/services/auth'
  const Validator = SimpleVueValidator.Validator
  export default {
    name: 'Login',
    mixins: [SimpleVueValidator.mixin],
    data () {
      return {
        user: '',
        password: '',
        formAlert: {
          show: false,
          type: 'error',
          msg: '',
        },
      }
    },
    validators: {
      user: function (value) {
        return Validator.value(value).required().minLength(4)
      },
      password: function (value) {
        return Validator.value(value).required().minLength(2)
      },
    },
    methods: {
      loginMethod: function () {
        if (!this.validation.hasError()) {
          const payload = { email: this.user, password: this.password }
          login(payload).then((res) => {
            const response = res.data
            console.log(response)
            this.$store.dispatch('loginUser', response)
          }).catch(err => {
            console.log('22', err)
            this.formAlert.show = true
            this.formAlert.msg = 'message'
          })
        }
      },
    },
  }
</script>

<style></style>
